export function calculateDurationWithSeconds(startDateStr, endDateStr) {
  // Parse the dates from the provided strings
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = endDate - startDate;

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
  const seconds = Math.floor((differenceInMilliseconds / 1000) % 60);

  // Calculate the remaining milliseconds after converting to hours, minutes, and seconds
  const milliseconds = differenceInMilliseconds % 1000; // remainder of milliseconds

  // Format the duration string
  return [
    `${hours} hours`,
    `${minutes} minutes`,
    `${seconds} seconds`,
    `${milliseconds} milliseconds`,
  ].join("<br/>");
}

export function getCampaignDateStatus(startDateStr, endDateStr) {
  // Parse the dates from the provided strings
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Get the current date
  const now = new Date();

  const isUrgent = () => {
    const timeRemaining = endDate - now;
    const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
    return timeRemaining <= twoDaysInMilliseconds && timeRemaining > 0;
  };

  const showStartsIn = () => {
    return startDate > now;
  };

  const startsIn = () => {
    const timeDiff = startDate - now;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      return "less than an hour";
    }
  };

  const endsIn = () => {
    const timeDiff = endDate - now;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      return "less than an hour";
    }
  };

  const startsOnNiceDate = () => {
    return formatNiceDate(startDate);
  };

  const endsOnNiceDate = () => {
    return formatNiceDate(endDate);
  };

  return {
    isUrgent: isUrgent(),
    showStartsIn: showStartsIn(),
    startsIn: startsIn(),
    endsIn: endsIn(),
    startsOnNiceDate: startsOnNiceDate(),
    endsOnNiceDate: endsOnNiceDate(),
  };
}

